import React, { useEffect, useState } from "react";
import BannerCard from "./BannerCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import clientConfig from "../client-config"; // Ensure this path is correct
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { i18n } = useTranslation();
  const [banners, setBanners] = useState([]);
  const [authToken, setAuthToken] = useState('');
  const [categories, setCategories] = useState({}); // To store category names

  const getAuthToken = async () => {
    try {
      const response = await axios.post(
        `${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`,
        {
          username: clientConfig.username,
          password: clientConfig.password,
        }
      );
      return response.data.token;
    } catch (err) {
      console.error("Error fetching auth token:", err);
      return null;
    }
  };

  const fetchCategoriesForPost = async (postId, token) => {
    try {
      const response = await axios.get(
        `${clientConfig.siteUrl}/wp-json/wp/v2/categories?post=${postId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      const categoriesData = response.data.reduce((acc, category) => {
        acc[category.id] = category.name;
        return acc;
      }, {});
      return categoriesData;
    } catch (error) {
      console.error("Error fetching categories:", error);
      return {};
    }
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        let token = authToken;

        if (!token) {
          token = await getAuthToken();
          if (token) {
            setAuthToken(token);
          }
        }

        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts?per_page=100&page=1`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        console.log(response);

        // Fetch featured media details
        const fetchMediaDetails = async (mediaId) => {
          try {
            const mediaResponse = await axios.get(
              `${clientConfig.siteUrl}/wp-json/wp/v2/media/${mediaId}`,
              {
                headers: { Authorization: `Bearer ${token}` }
              }
            );
            return mediaResponse.data.source_url;
          } catch (error) {
            console.error("Error fetching media details:", error);
            return '';
          }
        };

        // Add media URL to each post
        const updatedBanners = await Promise.all(response.data
          .filter(post => post.acf?.enable_home_banner?.includes("yes"))
          .map(async (post) => {
            const imageUrl = await fetchMediaDetails(post.featured_media);

            // Fetch category names
            const postCategories = post.categories || [];
            const categoriesData = await fetchCategoriesForPost(post.id, token);
            const categoryNames = postCategories.map(catId => categoriesData[catId] || 'Unknown Category');

            // Construct custom permalink
            const permalink = `https://healthspan.co.th/en/pedsupab/blog/${post.id}`;

            return {
              ...post,
              featured_image: imageUrl,
              category_name: i18n.language === "th"
                ? post.acf?.category_name_thai || categoryNames.join(', ')
                : post.acf?.category_name || categoryNames.join(', '),
              permalink: permalink, // Use the custom permalink
              priority: parseInt(post.acf?.priority) || 0
            };
          })
        );

        const sortedBanners = updatedBanners.sort((a, b) => a.priority - b.priority);

        setBanners(sortedBanners);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [authToken, i18n.language]);

  return (
    <div className="banner_option">
      <OwlCarousel
        className="owl-theme"
        loop
        items={1}
        margin={0}
        autoplay={true}
        autoplayTimeout={5000}
        nav
      >
        {banners.map((post) => (
          <div className="item slideropt" key={post.id}>
            <BannerCard
              image={post.featured_image} // Correct field for image URL
              title={post.category_name} // Correct field for category name
              description={post.title.rendered} // Correct field for post title
              permalink={post.permalink} // Use the custom permalink
            />
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default Banner;
