import React from "react";
import { Link } from "react-router-dom";

const BannerCard = ({ title, description, image, permalink }) => {
  return (
    <div className="banner_add">
      <Link to={permalink} target="_blank">
        <img src={image} alt={title} />
        <div className="cardcontainer">
          <div>
            <h3 className="sliderHeaderText">{title}</h3>
            <p className="sliderParaText">{description}</p>
            <div>
              <button className="seeDetails">SEE DETAILS</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BannerCard;
