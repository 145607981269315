import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Card, Col, Container, Nav, Row } from "react-bootstrap";
import Header from "../header";
import { useTranslation } from "react-i18next";
import Footer from "../footer";
import { Link, useParams } from "react-router-dom";
import clientConfig from '../../client-config';

const Blogpage = () => {
  const { t, i18n } = useTranslation();
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [authToken, setAuthToken] = useState('');

  // Function to get authentication token
  const getAuthToken = async () => {
    try {
      const response = await axios.post(`${clientConfig.siteUrl}/wp-json/jwt-auth/v1/token`, {
        username: clientConfig.username,
        password: clientConfig.password,
      });
      return response.data.token;
    } catch (error) {
      console.error('Error fetching auth token:', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = await getAuthToken();
        if (!token) {
          setError('Error fetching auth token');
          return;
        }
        setAuthToken(token);

        // Fetch the blog post
        const response = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/posts/${blogId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const post = response.data;

        // Fetch the featured media
        if (post.featured_media) {
          const mediaResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          post.featured_media_url = mediaResponse.data.source_url;
        }

        // Fetch the author name
        if (post.author) {
          const authorResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/users/${post.author}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          post.author_name = authorResponse.data.name;
        }

        // Fetch the categories
        if (post.categories.length > 0) {
          const categoriesResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/categories`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const categories = categoriesResponse.data.reduce((acc, category) => {
            acc[category.id] = category.name;
            return acc;
          }, {});
          post.category_names = post.categories.map(catId => categories[catId]);
        }

        // Fetch the tags
        if (post.tags.length > 0) {
          const tagsResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/tags`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const tags = tagsResponse.data.reduce((acc, tag) => {
            acc[tag.id] = tag.name;
            return acc;
          }, {});
          post.tag_names = post.tags.map(tagId => tags[tagId]);
        }

        setBlog(post);
      } catch (err) {
        setError('Error fetching blog post');
      } finally {
        setLoading(false);
      }
    };

    const fetchRecentPosts = async () => {
      try {
        const token = await getAuthToken();
        if (!token) {
          setError('Error fetching auth token');
          return;
        }

        const response = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/posts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            per_page: 4,
            orderby: 'date',
            order: 'desc'
          }
        });
        const posts = response.data;

        // Fetch media for recent posts
        const postsWithMedia = await Promise.all(posts.map(async (post) => {
          if (post.featured_media) {
            const mediaResponse = await axios.get(`${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            post.featured_media_url = mediaResponse.data.source_url;
          } else {
            post.featured_media_url = 'default_image_url'; // Set a default image URL if needed
          }
          return post;
        }));

        setRecentPosts(postsWithMedia);
      } catch (err) {
        console.error('Error fetching recent posts:', err);
      }
    };

    fetchData();
    fetchRecentPosts();
  }, [blogId]);

  const renderContent = (content) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };

  const truncateText = (text, wordLimit) => {
    if (!text) return ''; // Add this check to avoid splitting null or undefined text
    const words = text.split(' ');
    return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <main className="main blog-wrap-main">
      <Header />
      <section className="blog-banner min-h-96">
        <img
          src={blog?.featured_media_url || "default_banner_image_url"}
          style={{ objectFit: "cover" }}
          alt="#"
          className="blg-bnnr-img"
        />
        <div className="bblk-inner-part">
          <Link className="btn backbtn" to="/pedsupab">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="16"
              viewBox="0 0 34 16"
              fill="none"
            >
              <path
                d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H34V7H1V9Z"
                fill="white"
              />
            </svg>
            {t("back")}
          </Link>
        </div>
      </section>
      <section className="blog-content">
        <span className="detailsbtn">
          {i18n.language === "th"
            ? blog?.acf?.category_name_thai
            : blog?.category_names?.join(", ") || t("No Category")}
        </span>

        <div className="flex items-center gap-2">
          <p className="subtxt">
            {t("by")} <span>{blog?.author_name}</span>
          </p>
          <p className="subtxt">
            {blog?.date && new Date(blog?.date).toDateString()}
          </p>
        </div>

        <div className="content">
          {i18n.language === "th"
            ? blog?.acf?.post_description_thai &&
              renderContent(blog.acf.post_description_thai)
            : blog?.content?.rendered && renderContent(blog.content.rendered)}
        </div>

        <div className="tag_wrapper">
          <Container>
            <Card className="tagcard">
              <Nav>
                <Nav.Item>Tag:</Nav.Item>
                {blog?.tag_names?.map((tagName, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link eventKey={`#${tagName}`}>#{tagName}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card>
          </Container>
        </div>
      </section>
      <section className="recent-article">
        <div className="rhead-wrp">
          <span className="r-title">
            {t("RECENT")} {t("ARTICLE")}
          </span>
        </div>
        <div className="kd-content-card-wrap">
          <Row>
            {recentPosts.map((recentPost) => (
              <Col md={3} sm={6} key={recentPost.id}>
                <Link to={`/pedsupab/blog/${recentPost.id}`}>
                  <Card className="kd-content-cards p-1">
                    <Card.Img
                      variant="top"
                      src={recentPost.featured_media_url || "default_image_url"}
                      className="h-[185px] object-cover"
                    />
                    <Card.Body className="px-0">
                      <p className="rcent-title">
                        {i18n.language === "th"
                          ? recentPost?.acf?.post_title_thai
                          : truncateText(recentPost?.title?.rendered, 5)}
                      </p>
                      <Card.Text>
                        <small className="subtxt">
                          {recentPost.date &&
                            new Date(recentPost.date).toDateString()}
                        </small>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Blogpage;
