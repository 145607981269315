import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import "./knowledge.css";
import "./KnowledgeCustom.css";
import Slider from "react-slick";
import { Card, Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import clientConfig from '../client-config'; // Import your client configuration
import ArticlesCard from "../LandingPage/ArticlesCard";
import OwlCarousel from "react-owl-carousel";

const Knowledge = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [popularPosts, setPopularPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sliderPosts, setSliderPosts] = useState([]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const fetchSliderPosts = async () => {
      try {
        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts?per_page=5`
        );
        const posts = response.data;

        const sliderData = await Promise.all(
          posts.map(async (post) => {
            // Fetch featured media
            if (post.featured_media) {
              const mediaResponse = await axios.get(
                `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`
              );
              post.featured_media_url = mediaResponse.data.source_url;
            } else {
              post.featured_media_url = "default_image_url"; // Set a default image URL if needed
            }

            // Fetch categories
            const categoryNames = await Promise.all(
              post.categories.map(async (categoryId) => {
                const categoryResponse = await axios.get(
                  `${clientConfig.siteUrl}/wp-json/wp/v2/categories/${categoryId}`
                );
                return categoryResponse.data.name;
              })
            );
            post.categoryNames = categoryNames.join(", ");

            // Format post data for slider
            return {
              id: post.id,
              imageUrl: post.featured_media_url, // Use fetched or default image URL
              title: post.title.rendered,
              categoryNames: post.categoryNames, // Ensure categoryNames is set correctly
              slug: post.slug,
            };
          })
        );

        setSliderPosts(sliderData);
      } catch (error) {
        console.error("Error fetching slider posts:", error);
      }
    };

    const fetchPopularPosts = async () => {
      try {
        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/posts?filter[orderby]=popular`
        );
        const posts = response.data;

        const postsWithMediaAndCategories = await Promise.all(
          posts.map(async (post) => {
            // Fetch featured media
            if (post.featured_media) {
              const mediaResponse = await axios.get(
                `${clientConfig.siteUrl}/wp-json/wp/v2/media/${post.featured_media}`
              );
              post.featured_media_url = mediaResponse.data.source_url;
            } else {
              post.featured_media_url = "default_image_url"; // Set a default image URL if needed
            }

            // Fetch categories
            const categoryNames = await Promise.all(
              post.categories.map(async (categoryId) => {
                const categoryResponse = await axios.get(
                  `${clientConfig.siteUrl}/wp-json/wp/v2/categories/${categoryId}`
                );
                if (post.categories.length > 0) {
                  post.category_slug = categoryResponse.data.slug;
                } else {
                  post.category_slug = "uncategorized"; // Set a default category slug if needed
                }
                return categoryResponse.data.name;
              })
            );
            post.category_names = categoryNames.join(", ");

            return post;
          })
        );

        setPopularPosts(postsWithMediaAndCategories);
      } catch (error) {
        console.error("Error fetching popular posts:", error);
      }
    };

    const fetchTags = async () => {
      try {
        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/tags`
        );
        setTags(response.data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${clientConfig.siteUrl}/wp-json/wp/v2/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSliderPosts();
    fetchPopularPosts();
    fetchTags();
    fetchCategories();
  }, []);

  // hack to fix popular posts rendering issue in build
  const [_reRender, setReRender] = useState(false);
  useEffect(() => {
    setReRender((prev) => !prev); // Force re-render
  }, [popularPosts]);

  return (
    <main className="main">
      <Header />
      <section className="slider_wrapper">
        <button
          className="!bg-[#ffc228] flex items-center justify-center rounded-full p-0.5 px-4 absolute top-5 left-5 z-10"
          onClick={() => navigate("/")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            viewBox="0 0 24 24"
            className="w-10 h-10"
            id="angle-left"
          >
            <path
              fill="#000000"
              d="M11.3,12l3.5-3.5c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-4.2,4.2l0,0c-0.4,0.4-0.4,1,0,1.4l4.2,4.2c0.2,0.2,0.4,0.3,0.7,0.3l0,0c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L11.3,12z"
            ></path>
          </svg>

          <span className="!font-bold text-2xl">{t("Healthspan")}</span>
        </button>
        <Slider {...settings}>
          {sliderPosts.map((post) => (
            <div key={post.id}>
              <Link to={`/pedsupab/blog/${post.id}`}>
                <Card
                  className="slider-card"
                  style={{
                    backgroundImage: `url(${post.imageUrl})`,
                  }}
                >
                  <Card.Title>
                    {i18n.language === "th" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.acf?.post_title_thai,
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered,
                        }}
                      />
                    )}
                  </Card.Title>
                  {/* <Card.Title dangerouslySetInnerHTML={{ __html: post.title }}></Card.Title> */}
                  {/* <Card.Subtitle dangerouslySetInnerHTML={{ __html: post.categoryNames }}></Card.Subtitle> */}
                  <Card.Subtitle>
                    {i18n.language === "th" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.acf?.category_name_thai,
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.categoryNames,
                        }}
                      />
                    )}
                  </Card.Subtitle>
                </Card>
              </Link>
            </div>
          ))}
        </Slider>
      </section>

      <section className="popular-section popular_video p-0">
        <Card.Subtitle className="heading">
          {i18n.language === "th" ? (
            "บทความยอดนิยม"
          ) : (
            <>
              {t("popular")} <span>{t("articles")}</span>
            </>
          )}
        </Card.Subtitle>

        <div className="defpadding nppadding_bottom sm:-mt-24 -mt-10">
          <div className="feature-three-blogs-w feature-blog">
            <OwlCarousel
              className="owl-theme"
              loop
              items={3}
              margin={16}
              autoplay={true}
              autoplayTimeout={5000}
              nav
              responsive={{
                1: {
                  items: 1,
                },
                767: {
                  items: 2,
                },
                1025: {
                  items: 3,
                },
              }}
            >
              {popularPosts.map((post) => (
                <ArticlesCard
                  key={post.id}
                  post={post}
                  categories={categories}
                />
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section className="tag_wrapper">
        <Container>
          <Card className="tagcard">
            <Nav onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}>
              <Nav.Item>Tag:</Nav.Item>
              {tags.map((tag) => (
                <Nav.Item key={tag.id}>
                  <Nav.Link eventKey={`#${tag.slug}`}>#{tag.name}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card>
        </Container>
      </section>

      <section className="popular-section popular_video mb-5">
        <Container>
          <Card.Subtitle className="heading">
            {i18n.language === "th" ? (
              "วิดีโอยอดนิยม"
            ) : (
              <>
                {t("popular")} <span>{t("videos")}</span>
              </>
            )}
          </Card.Subtitle>
          <div className="row">
            <div className="col-md-8 col-12">
              <Card>
                <iframe
                  src="https://www.youtube.com/embed/tKB00XYaSfo"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={t("video")}
                  className="!aspect-video w-full rounded"
                />
              </Card>
            </div>

            <div className="col-md-4 col-12 padding-left0">
              <div className="h-fit flex flex-col gap-3">
                <Card className="related-video mb-0">
                  <Card.Title className="w-full !text-sm line-clamp-2">
                    {t(
                      "เซ็กส์..และความชอบ ที่คู่รักควรเปิดใจ l เพศต้องพูด EP.4 @chawisachen TALK TO PEACH"
                    )}
                  </Card.Title>
                  <a
                    href="https://youtu.be/if7peOZ7pXc?si=hDdIGC-5XZc4HJaC"
                    className="h-full w-fit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/if7peOZ7pXc/mqdefault.jpg"
                      alt=""
                      className="aspect-video rounded !w-60"
                    />
                  </a>
                </Card>
                <Card className="related-video mb-0">
                  <Card.Title className="w-full !text-sm line-clamp-2">
                    {t(
                      "เซ็กส์...ในชีวิตจริงไม่เหมือนในจินตนาการ | เพศต้องพูด EP.1 @GenitiqueClinic"
                    )}
                  </Card.Title>
                  <a
                    href="https://youtu.be/tKB00XYaSfo?si=13h_VnXtlF-J-St9"
                    className="h-full w-fit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/tKB00XYaSfo/mqdefault.jpg"
                      alt=""
                      className="aspect-video rounded !w-60"
                    />
                  </a>
                </Card>
                <Card className="related-video mb-0">
                  <Card.Title className="w-full !text-sm line-clamp-2">
                    {t(
                      "อย่าให้โรคทำลายรัก..ความจริงของโรคติดต่อทางเพศสัมพันธ์ | เพศต้องพูด EP.2 @GenitiqueClinic"
                    )}
                  </Card.Title>
                  <a
                    href="https://youtu.be/Wb95n-3_YrM?si=aR3CjUBZ2BW7J_I8"
                    className="h-full w-fit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img.youtube.com/vi/Wb95n-3_YrM/mqdefault.jpg"
                      alt="#"
                      className="aspect-video rounded !w-60"
                    />
                  </a>
                </Card>
                <div className="flex items-center justify-center">
                  <Link to={"/video"} className="show-more">
                    {t("see_more")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </main>
  );
};

export default Knowledge;
